<template>
  <r-e-dialog
      title="撤销确认"
      class="layer-contract-detail"
      :visible.sync="addDialogVisible"
      show-footer
      top="10vh"
      width="600px"
      @click-submit="clickSubmit"
      @click-cancel="clickCancel"
      @close="clickCancel"
  >
<!--表单-->
    <el-form :rules="rules" ref="form" :model="formdata"   >
      <el-form-item label="姓名" prop="name" label-width="100px" size="small">
          <el-input   v-model="name" style="width: 400px" placeholder="请输入姓名" disabled></el-input>
      </el-form-item>
      <el-form-item label="证件号码" prop="idCard" label-width="100px" size="small">
          <el-input  v-model="idCard" style="width: 400px" placeholder="请输入证件号码" disabled></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone" label-width="100px" size="small">
          <el-input  v-model="phone" style="width: 400px" placeholder="请输入联系电话" disabled></el-input>
      </el-form-item>
      <div style="margin-left:30px">注:撤销操作将使此租户移除黑名单，请谨慎操作</div>
    </el-form>

  </r-e-dialog>
</template>

<script>
import {risedialogapi,risedialogapixiangqing,getBlackRevokeAPI} from "@/api/tenant-management";
import {extractUrlParameter} from "@storage/cookies";
import { MessageSuccess } from "@custom/message";
export default {
  name: "dialog-add-blacklist",
  data() {
    return {
      uuid:'',
      addDialogVisible: false,
    }
  },
  methods:{
    // 撤销确认回显
    openRevokeclick(row){
      console.log(row)
      this.uuid = row.leasorUuid
      console.log('uuid',this.uuid);
      this.name = row.leasorName;
      this.idCard = row.idCard;
      this.phone = row.leasorTel;
      this.addDialogVisible = true
    },
    // 弹框确认
    clickSubmit(){
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      getBlackRevokeAPI({idCard:this.idCard}).then(res => {
            console.log(res)
            MessageSuccess('撤销成功');
            this.$emit('handleSearch');
            this.clickCancel();
          }).finally(() => loading.close());
    },
    // 关闭弹框
    clickCancel(){
      this.addDialogVisible = false
      // this.formdata = {}

    },
    handleSuccess(response) {
      console.log('我是添加',response.file.response.returnObject.info.uuid,response)
      const uuid = response.file.response.returnObject.info.uuid;
      this.formdata.fileList.push(uuid)
      console.log(this.formdata.fileList)
      this.formdata.samplePhotos =  this.formdata.fileList.join();
      // //2022/03/22 王江毅 图片上传后 将保存图片的字段拆分(拆分后判断长度等不等于0)后过滤(过滤空字符串，如果以空字符串进行拆分会返回长度为1的数组,且该值为空字符串)
      // const isTrue = this.formdata.samplePhotos.split(",").filter(item => item !== '').length !== 0;
      //
      // this.formdata.samplePhotos += isTrue ? ("," + uuid) : uuid;
    },
    handleRemove({fileList}) {
      console.log('我是删除',fileList)
      let arr = []
      fileList.forEach(item => {
        if(item.name){
          arr.push(item.name)
        }else {
          arr.push(item.response.returnObject.info.uuid)
        }
      })
      this.formdata.fileList = arr
      this.formdata.samplePhotos = arr.join()
      // const fileListName = fileList.map(v => v.name.split(".")[0]);
      // console.log(fileListName)
      // this.formdata.samplePhotos = fileListName.join(",");
      // this.formdata.fileList = this.formdata.samplePhotos.split(",").filter(item => item !== '');

    },
  }
}
</script>

<style scoped lang="scss">
.invoice_top{
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
</style>